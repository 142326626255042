'use strict'

###*
 # @ngdoc service
 # @name lpaMapUtils.factory:MapSync

 # @description
 # This factory handles all the keeping in sync stuff of the different maps

###
angular
  .module 'lpaMapUtils'
  .factory 'MapSync', [
    '$log',
    'MundoMap',
    'MundoSocket',
    '$rootScope',
    'LpaMapUtils',
    'Incidents',
    ($log, MundoMap, MundoSocket, $rootScope, LpaMapUtils, Incidents) ->

      MapSyncBase = {}
      MapSyncBase.incidents = {}
      MapSyncBase.closedIncidents = {}
      MapSyncBase.closedIncidentTimeout = 30
      MapSyncBase.units = {}
      MapSyncBase.units.visible = []
      MapSyncBase.incidentListener = false
      MapSyncBase.unitListener = false

      MapSyncBase.incidents.enable = (map, features, incidents, incidentLayer, options) ->
        if !MapSyncBase.incidentListener
          MapSyncBase.incidentListener = true
          _defaults =
            incident: null
            listen: ['incident_create', 'incident_update', 'incident_close']
            filter: null
            close: false
            window: null
            routes: null
            onUpdate: null

          options = angular.extend {}, _defaults, options

          $rootScope
            .$on MundoSocket.options.tenantEventConsumer.dataEventName, (event, data) ->
              callback = switch data.event.metadata.dataType
                when 'incident_create' then (event, data) ->
                  if options.listen.indexOf('incident_create') > -1
                    incidentId = data.event.incident.id
                    MapSyncBase.createIncident incidentId

                when 'incident_update' then (event, data) ->
                  if options.listen.indexOf('incident_update') > -1
                    incidentId = data.event.incident.id
                    MapSyncBase.updateIncident incidentId,
                      allowPopups: false

                when 'incident_close' then (event, data) ->
                  if options.listen.indexOf('incident_close') > -1
                    incidentId = data.event.incident.id
                    MapSyncBase.closeIncident incidentId

                when 'dispatch_unit_create' then
                  # Dispatch unit created.. add it to map?
                when 'dispatch_unit_update' then
                  # dispatch unit updated.. irrelevant?
                when 'dispatch_unit_close' then
                  # dispatch unit closed, remove from map?
                else null

              if callback
                callback(event, data)

        MapSyncBase.closeIncident = (incidentId) ->
          $log.debug("MapSync/Incidents: Closing incident '#{incidentId}'")
          LpaMapUtils.removeIncidentFromMap incidentId, features, incidentLayer, incidents
          MapSyncBase.closedIncidents[incidentId] = Math.floor(Date.now() /1000)

          if options.filter?
            options.filter()

          if options.incident and (incidentId == options.incident) and options.close
            if options.window?
              options.window.close()

          if options.routes
            MundoMap.getLayerSource(options.routes).clear()

        MapSyncBase.isIncidentClosed = (incidentId) ->
          now = Math.floor(Date.now() /1000)
          closed = false

          # The incident is closed if it was closed less than N seconds ago
          if MapSyncBase.closedIncidents[incidentId]?
            if (now - MapSyncBase.closedIncidents[incidentId]) <= MapSyncBase.closedIncidentTimeout
              closed = true

          # Remove all incidents which were closed over N seconds ago from the list
          for id, ts of MapSyncBase.closedIncidents
            if (now - ts) > MapSyncBase.closedIncidentTimeout
              delete MapSyncBase.closedIncidents[id]

          return closed

        MapSyncBase.createIncident = (incidentId, parameters = {}) ->
          $log.debug("MapSync/Incidents: Creating incident '#{incidentId}'")
          MapSyncBase.realUpdateIncident incidentId, parameters

        MapSyncBase.updateIncident = _.debounce (incidentId, parameters = {}) ->
          $log.debug("MapSync/Incidents: Trying to update incident '#{incidentId}'")
          MapSyncBase.realUpdateIncident incidentId, parameters
        , 1000,
          trailing: true
          leading: false

        MapSyncBase.realUpdateIncident = (incidentId, parameters = {}) ->
          defaults =
            allowPopups: true

          parameters = angular.merge {}, defaults, parameters

          if MapSyncBase.isIncidentClosed incidentId
            $log.debug("MapSync/Incidents: Not updating incident '#{incidentId}' since it was closed recently")
            return

          if !options.incident? or (options.incident == incidentId)
            Incidents.one(incidentId).get().then (incident) ->
              if MapSyncBase.isIncidentClosed incidentId
                $log.debug("MapSync/Incidents: Not updating incident '#{incidentId}' since it was closed recently")
                return

              if incident.closedAt
                MapSyncBase.closeIncident(incident.id)
              else
                $log.debug("MapSync/Incidents: Updating incident '#{incidentId}'")

                oldIncident = angular.copy incidents[incidentId]
                LpaMapUtils.addIncidentToMap incident, features, incidentLayer, incidents

                if parameters.allowPopups
                  if $rootScope.userSettings? && $rootScope.userSettings.popups.values?
                    if $rootScope.userSettings.prioPopups.values?
                      if _.has $rootScope.userSettings.prioPopups.values, incident.incidentPriority.id
                        if $rootScope.userSettings.prioPopups.values[incident.incidentPriority.id] == true
                          if _.has $rootScope.userSettings.popups.values, incident.dispatchGroup.id
                            if $rootScope.userSettings.popups.values[incident.dispatchGroup.id] == true
                              LpaMapUtils.openIncidentWindow incident

                if options.filter?
                  options.filter()

                if options.onUpdate?
                  options.onUpdate oldIncident, incident

      MapSyncBase.units.enable = (options) ->
        if !MapSyncBase.unitListener
          MapSyncBase.unitListener = true

          _defaults =
            unit: null
            visibleUnits: null
            filter: null
            incidents: null
            incidentLayer: null
            routeLayer: null
            onEventUpdate: null
            markerLayer: null
            markers: null
            trailLayer: null
            automaticallyAddNewStatuses: true

          options = angular.extend {}, _defaults, options

          if options? and options.unit?
            MapSyncBase.units.track = options.unit

          if options? and options.visibleUnits
            MapSyncBase.units.visible = options.visibleUnits

          $rootScope
            .$on MundoSocket.options.unitEventConsumer.dataEventName, (event, data) ->
              if data.event.incident?
                # if options.incidents? and options.incidentLayer? and options.markers?
                if options.incidents?
                  oldIncident = angular.copy options.incidents[data.event.incident.id]
                  if data.event.incident.closed_at
                    # Remove incident from incidents
                    # Remove Routes from map
                    LpaMapUtils.removeIncidentFromMap(
                      data.event.incident.id
                      options.markers
                      options.incidentLayer
                      options.incidents
                    )

                  else
                    Incidents.one(data.event.incident.id).get().then (incident) ->
                      LpaMapUtils.addIncidentToMap incident, options.markers, options.incidentLayer, options.incidents
                      if options.onEventUpdate?
                        options.onEventUpdate oldIncident
                else
                  if (data.event.metadata.dataType == 'task_assign' || data.event.metadata.dataType == 'task_relieve')
                    Incidents.one(data.event.incident.id).get().then (incident) ->
                      LpaMapUtils.addIncidentToMap incident, options.markers, options.incidentLayer, options.incidents

          $rootScope
            .$on MundoSocket.options.unitStatusConsumer.dataEventName, (event, data) ->
              status = data.event
              try
                foundStatuses = _.filter options.statuses, (s) -> s['_id'] == status['_id']

                if (angular.isArray foundStatuses) and
                ((foundStatuses.length == 0 and options.automaticallyAddNewStatuses) or
                (foundStatuses.length > 0 and (foundStatuses[0].timestamp.updated <= status.timestamp.updated)))
                  addStatus = false

                  if MapSyncBase.units.track?
                    MapSyncBase.units.addPanAndZoom options.map, 500
                    addStatus = true

                    if MapSyncBase.units.track == status.unit.id
                      $rootScope.$evalAsync () ->
                        options.map._options.follow.objects[status.unit.id] = options.markers[status.unit.id]
                        $rootScope.unit = status

                  if (!MapSyncBase.units.track?) and
                  (!MapSyncBase.units.visible? or (MapSyncBase.units.visible.length == 0))
                    addStatus = true

                  if MapSyncBase.units.visible and (_.indexOf MapSyncBase.units.visible, status.unit.id) > -1
                    addStatus = true

                  if addStatus
                    LpaMapUtils.addStatusToMap(
                      status,
                      options.markers,
                      options.markerLayer,
                      options.statuses,
                      null,
                      null,
                      options.trailLayer
                    )

                  if options.filter?
                    options.filter()

              catch
                $log.warn 'LpaDispatching: Error parsing message data => data: ', data

      MapSyncBase.units.setTrackingVehicle = (unit) ->
        MapSyncBase.units.track = unit

      MapSyncBase.units.addVisible = (unit) ->
        MapSyncBase.units.visible.push unit

      MapSyncBase.units.addPanAndZoom = (map, duration) ->

        pan = ol.animation.pan
          duration: duration
          source: map.getView().getCenter()
          easing: ol.easing.linear

        zoom = ol.animation.zoom
          duration: duration
          resolution: map.getView().getResolution()
          easing: ol.easing.linear

        map.beforeRender(pan)
        map.beforeRender(zoom)

      MapSyncBase
    ]
